import { Button, Container, InputBase, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const StyledContainer = styled(Container)({
  height: '100vh', // コンテナ全体を画面全体に広げる
  display: 'flex', // 子要素をフレックスに設定
  flexDirection: 'column', // 垂直方向に並べる
  justifyContent: 'center', // 中央揃え
  alignItems: 'center', // 中央揃え
  maxWidth: '100% !important', // maxWidthを無視して画面幅いっぱいを使う
});

export const StyledButton = styled(Button)({
  margin: '10px', // マージンを追加
});

export const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px', // フィールドとボタンの間のスペース
});

export const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px', // ボタン間のスペース
});

export const StyledTextField = styled(InputBase)({
  width: '60vw', // 横幅をビューポート幅の40%に設定
  padding: '6px 12px',
  border: '1px solid #ccc',
  borderRadius: '4px',
  fontSize: '16px',
  margin: '8px 0',
});

export const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  marginTop: theme.spacing(1),
}));

export const SuccessMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
  marginTop: theme.spacing(1),
}));
