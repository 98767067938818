import React, { useState } from 'react';
import { PlayerData } from '../../entities/PlayerData';

import { AxiosResponse } from 'axios';

import { Typography, ThemeProvider } from '@mui/material';
import {
  StyledContainer,
  StyledButton,
  StyledForm,
  ButtonContainer,
  StyledTextField,
} from '../../components/Styled/Styles';

import VolumeControl from '../../components/VolumeControl/VolumeControl';
import RuleDialog from '../../components/RuleDialog/RuleDialog';
import FeedbackModal from '../../components/FeedbackModal/FeedbackModal';
import { Theme } from '../../components/Theme/ColorTheme';
import { HttpMethod } from '../../entities/RequestParams';
import { requestWithRetry } from '../../utils/ApiUtils';

function Title({ goToMatching }: { goToMatching: (data: PlayerData) => void }) {
  const [name, setName] = useState('');
  const [open, setOpen] = useState(false); // ルール表示のためのステート
  const [feedbackOpen, setFeedbackOpen] = useState(false); // フィードバックモーダル表示のためのステート

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    requestWithRetry({
      maxRetryCount: 0,
      method: HttpMethod.POST,
      path: `/players`,
      onError: function (error: unknown, retryCount: number) {
        console.error(`Error creating player (retry: ${retryCount}):${error}`);
      },
      data: {
        name,
      },
      onSuccess: function (response: AxiosResponse) {
        goToMatching(response.data);
      },
    });
  };

  const handleFeedbackOpen = () => {
    setFeedbackOpen(true);
  };

  const handleFeedbackClose = () => {
    setFeedbackOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ThemeProvider theme={Theme}>
        <StyledContainer color="default">
          <Typography variant="h4" component="h1" gutterBottom>
            混老頭麻雀
          </Typography>
          <StyledForm onSubmit={handleSubmit}>
            <StyledTextField
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
              }
              fullWidth
              placeholder="プレイヤー名を入力"
              sx={{
                backgroundColor: '#fff',
              }}
            />
            <ButtonContainer>
              <StyledButton variant="contained" onClick={handleOpen}>
                ルール
              </StyledButton>
              <StyledButton variant="contained" type="submit">
                次へ
              </StyledButton>
            </ButtonContainer>
          </StyledForm>
          <RuleDialog open={open} handleClose={handleClose} />
          <StyledButton
            variant="contained"
            onClick={handleFeedbackOpen}
            sx={{ position: 'absolute', top: 10, right: 10 }}>
            フィードバック
          </StyledButton>
          <FeedbackModal
            open={feedbackOpen}
            handleClose={handleFeedbackClose}
          />
          <Typography variant="body1" component="h1" gutterBottom>
            初期ロードに時間がかかる場合があります
          </Typography>
        </StyledContainer>
      </ThemeProvider>
      <VolumeControl />
    </>
  );
}

export default Title;
