import React, { useState, useEffect, useRef } from 'react';
import './Tile.css';

type TileProps = {
  /** タイル画像リソースURL */
  tileImage: string;
  /** 暗くするかどうか(例: 暗カンする牌を選択する際に不要な牌) */
  darkenTile?: boolean;
  /** 横向きに設置するか (大抵Meld(鳴き牌)の表示に用いる) */
  horizontal?: boolean;
  /** ホバーアニメーションを必要とするか */
  needHoverAnim?: boolean;
  /** 山から引いた直後の牌か */
  immediatelyAfterDraw?: boolean;
  /** 牌クリックコールバック */
  onTileClick?: () => void;
} & React.CSSProperties;

const Tile: React.FC<TileProps> = ({
  tileImage,
  darkenTile,
  horizontal,
  needHoverAnim = false,
  immediatelyAfterDraw = false,
  onTileClick,
  ...styles
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [clickEnabled, setClickEnabled] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isHovered) {
      timerRef.current = setTimeout(() => {
        setClickEnabled(true);
      }, 1000);
    } else {
      setClickEnabled(false);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [isHovered]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleTouchStart = () => {
    setIsHovered(true);
  };

  const handleTouchEnd = () => {
    setTimeout(() => setIsHovered(false), 300);
  };

  const handleClick = () => {
    if ((isHovered || clickEnabled) && onTileClick) {
      onTileClick();
    }
  };

  return (
    <img
      style={styles}
      src={tileImage}
      alt="tile"
      className={`
        tile
        ${horizontal ? 'tile-horizontal' : ''}
        ${needHoverAnim ? 'tile-hover-anim' : ''}
        ${immediatelyAfterDraw ? 'tile-immediately-after-draw' : ''}
        ${darkenTile ? 'tile-darken' : ''}
      `}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onClick={handleClick}
      onDoubleClick={onTileClick} // ダブルタップ時も発火
    />
  );
};

export default Tile;
