import React, { useEffect, useState } from 'react';
import './App.css';
import Main from './pages/Main/Main';

function App() {
  const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {
    const checkOrientation = () => {
      const isMobile = /Mobile|Android|iP(hone|od|ad)/i.test(navigator.userAgent);
      const landscape = window.matchMedia('(orientation: landscape)').matches;
      setIsLandscape(isMobile && landscape);
    };
    checkOrientation();
    window.addEventListener('resize', checkOrientation);
    return () => {
      window.removeEventListener('resize', checkOrientation);
    };
  }, []);

  return (
    <div className="app">
      {isLandscape ? (
        <div className="warning">横持ちには対応していません</div>
      ) : (
        <Main />
      )}
    </div>
  );
}

export default App;
