import React from 'react';
import Tile from '../Tile/Tile';
import './Meld.css';

type MeldProps = {
  meld: string[][];
};

const tileToVerticalImagePath: { [key: string]: string } = {
  '1m': 'assets/images/tile/manzu/p_ms1_1.gif',
  '9m': 'assets/images/tile/manzu/p_ms9_1.gif',
  '1p': 'assets/images/tile/pinzu/p_ps1_1.gif',
  '9p': 'assets/images/tile/pinzu/p_ps9_1.gif',
  '1s': 'assets/images/tile/sozu/p_ss1_1.gif',
  '9s': 'assets/images/tile/sozu/p_ss9_1.gif',
  '1j': 'assets/images/tile/jihai/p_ji_e_1.gif',
  '2j': 'assets/images/tile/jihai/p_ji_s_1.gif',
  '3j': 'assets/images/tile/jihai/p_ji_w_1.gif',
  '4j': 'assets/images/tile/jihai/p_ji_n_1.gif',
  '5j': 'assets/images/tile/jihai/p_no_1.gif',
  '6j': 'assets/images/tile/jihai/p_ji_h_1.gif',
  '7j': 'assets/images/tile/jihai/p_ji_c_1.gif',
};

const tileToHorizontalImagePath: { [key: string]: string } = {
  '1m': 'assets/images/tile/manzu/p_ms1_4.gif',
  '9m': 'assets/images/tile/manzu/p_ms9_4.gif',
  '1p': 'assets/images/tile/pinzu/p_ps1_4.gif',
  '9p': 'assets/images/tile/pinzu/p_ps9_4.gif',
  '1s': 'assets/images/tile/sozu/p_ss1_4.gif',
  '9s': 'assets/images/tile/sozu/p_ss9_4.gif',
  '1j': 'assets/images/tile/jihai/p_ji_e_4.gif',
  '2j': 'assets/images/tile/jihai/p_ji_s_4.gif',
  '3j': 'assets/images/tile/jihai/p_ji_w_4.gif',
  '4j': 'assets/images/tile/jihai/p_ji_n_4.gif',
  '5j': 'assets/images/tile/jihai/p_no_4.gif',
  '6j': 'assets/images/tile/jihai/p_ji_h_4.gif',
  '7j': 'assets/images/tile/jihai/p_ji_c_4.gif',
};

const tileToAnkanSideImagePath = 'assets/images/tile/back/p_bk_1.gif';

const Meld: React.FC<MeldProps> = ({ meld }) => {
  if (meld.length === 0) return <></>;
  return (
    <>
      {meld.map((meld, index) => (
        <div key={index} className="meld-group">
          {meld[0] === 'pon' && (
            <>
              <Tile tileImage={tileToVerticalImagePath[meld[1]]} />
              <Tile
                tileImage={tileToHorizontalImagePath[meld[2]]}
                horizontal={true}
              />
              <Tile tileImage={tileToVerticalImagePath[meld[3]]} />
            </>
          )}
          {meld[0] === 'ankan' && (
            <>
              <Tile tileImage={tileToAnkanSideImagePath} />
              <Tile tileImage={tileToVerticalImagePath[meld[2]]} />
              <Tile tileImage={tileToVerticalImagePath[meld[3]]} />
              <Tile tileImage={tileToAnkanSideImagePath} />
            </>
          )}
          {meld[0] === 'kakan' && (
            <>
              <Tile tileImage={tileToVerticalImagePath[meld[1]]} />
              <div className="meld-vertical-group">
                <Tile
                  tileImage={tileToHorizontalImagePath[meld[2]]}
                  horizontal={true}
                />
                <Tile
                  tileImage={tileToHorizontalImagePath[meld[3]]}
                  horizontal={true}
                />
              </div>
              <Tile tileImage={tileToVerticalImagePath[meld[4]]} />
            </>
          )}
          {meld[0] === 'daiminkan' && (
            <>
              <Tile tileImage={tileToVerticalImagePath[meld[1]]} />
              <Tile tileImage={tileToVerticalImagePath[meld[2]]} />
              <Tile
                tileImage={tileToHorizontalImagePath[meld[3]]}
                horizontal={true}
              />
              <Tile tileImage={tileToVerticalImagePath[meld[4]]} />
            </>
          )}
        </div>
      ))}
    </>
  );
};

export default Meld;
