import React from 'react';
import './Hand.css';
import Tile from '../Tile/Tile';

const tileToImagePath: { [key: string]: string } = {
  '1m': 'assets/images/tile/manzu/p_ms1_0.gif',
  '9m': 'assets/images/tile/manzu/p_ms9_0.gif',
  '1p': 'assets/images/tile/pinzu/p_ps1_0.gif',
  '9p': 'assets/images/tile/pinzu/p_ps9_0.gif',
  '1s': 'assets/images/tile/sozu/p_ss1_0.gif',
  '9s': 'assets/images/tile/sozu/p_ss9_0.gif',
  '1j': 'assets/images/tile/jihai/p_ji_e_0.gif',
  '2j': 'assets/images/tile/jihai/p_ji_s_0.gif',
  '3j': 'assets/images/tile/jihai/p_ji_w_0.gif',
  '4j': 'assets/images/tile/jihai/p_ji_n_0.gif',
  '5j': 'assets/images/tile/jihai/p_no_0.gif',
  '6j': 'assets/images/tile/jihai/p_ji_h_0.gif',
  '7j': 'assets/images/tile/jihai/p_ji_c_0.gif',
};

type HandProps = {
  tiles: string[];
  gameStatus?: string;
  canAnkanTiles?: string[];
  canKakanTiles?: string[];
  isTurnPlayer?: boolean;
  immediatelyAfterDraw: boolean;
  onTileClick?: (tile: string) => void; // 打牌する牌を選択するステータスの場合のコールバック
  handleAnkanClick?: (tile: string) => void; // 暗カンする牌を選択するステータスの場合のコールバック
  handleKakanClick?: (tile: string) => void; // 加カンする牌を選択するステータスの場合のコールバック
};

const Hand: React.FC<HandProps> = ({
  tiles,
  gameStatus,
  canAnkanTiles,
  canKakanTiles,
  isTurnPlayer,
  immediatelyAfterDraw,
  onTileClick,
  handleAnkanClick,
  handleKakanClick,
}) => {
  // 牌を暗くするかどうかの判定するメソッド
  // 例: 暗カンする際は関係のない牌を暗くする
  const darkenTile = (tile: string): boolean => {
    // TODO: 暗カンの判定しかしていないが、将来的には加カンも必要
    // どの牌で鳴くかを選択するステータスでない場合は暗くしない
    if (gameStatus !== 'meld_tile_selection') {
      return false;
    }

    // ターンプレイヤーでない場合は暗くしない
    if (!isTurnPlayer) {
      return false;
    }

    // 暗カンできる牌は暗くしない
    if (canAnkanTiles && canAnkanTiles.includes(tile)) {
      return false;
    }

    // 加カンできる牌は暗くしない
    if (canKakanTiles && canKakanTiles.includes(tile)) {
      return false;
    }
    return true;
  };

  const onTileClickWrapper = (tile: string) => {
    if (gameStatus === 'tile_selection') {
      // 打牌する牌を選択するステータスの場合
      onTileClick && onTileClick(tile);
    } else if (gameStatus === 'meld_tile_selection') {
      // カンする牌を選択するステータスの場合
      if (canAnkanTiles && canAnkanTiles.includes(tile)) {
        handleAnkanClick && handleAnkanClick(tile);
      } else if (canKakanTiles && canKakanTiles.includes(tile)) {
        handleKakanClick && handleKakanClick(tile);
      }
    }
  };

  return (
    <div className="hand">
      {tiles.map((tile, index) => (
        <Tile
          key={index}
          tileImage={tileToImagePath[tile]}
          darkenTile={darkenTile(tile)}
          needHoverAnim={true}
          onTileClick={() => onTileClickWrapper(tile)}
          immediatelyAfterDraw={
            immediatelyAfterDraw && index === tiles.length - 1
          }
        />
      ))}
    </div>
  );
};

export default Hand;
