import React from 'react';
import { useEffect, useState, useRef } from 'react';
import ActionCable from 'actioncable';

import { AxiosResponse } from 'axios';

import { ThemeProvider } from '@mui/material';

import './BattleField.css';

import Hand from '../../components/Hand/Hand';
import Meld from '../../components/Meld/Meld';
import Discard from '../../components/Discard/Discard';
import OpponentHand from '../../components/OpponentHand/OpponentHand';
import OpponentMeld from '../../components/OpponentMeld/OpponentMeld';
import FuritenLabel from '../../components/FuritenLabel/FuritenLabel';
import ActionButton from '../../components/ActionButton/ActionButton';
import CenterPanel from '../../components/CenterPanel/CenterPanel';
import ReloadButton from '../../components/ReloadButton/ReloadButton';
import { BattleFieldContainer } from '../../components/Styled/BattleFieldContainer';
import { drawSound } from '../../components/Sound/DrawSound';
import { showActionSound } from '../../components/Sound/ShowActionSound';
import { GameData } from '../../entities/GameData';
import { Theme } from '../../components/Theme/ColorTheme';
import { HttpMethod } from '../../entities/RequestParams';
import { webSocketUrl, requestWithRetry } from '../../utils/ApiUtils';

function BattleField({
  gameData,
  goToInterval,
}: {
  gameData: GameData;
  goToInterval: () => void;
}) {
  const [tileList, setTileList] = useState<string[]>([]); // 自分の手牌
  const [meld, setMeld] = useState<string[][]>([]); // 自分の鳴いた牌
  const [opponentHandNum, setOpponentHandNum] = useState<number>(13); // 相手の手牌枚数
  const [opponentMeld, setOpponentMeld] = useState<string[][]>([]); // 相手の鳴いた牌
  const [canTsumo, setCanTsumo] = useState<boolean>(false); // ツモ可能かどうか
  const [canRon, setCanRon] = useState<boolean>(false); // ロン可能かどうか
  const [canPon, setCanPon] = useState<boolean>(false); // ポン可能かどうか
  const [canCallKan, setCanCallKan] = useState<boolean>(false); // カン宣言(暗カンか加カン)可能かどうか
  const [canAnkanTiles, setCanAnkanTiles] = useState<string[]>([]); // 暗カン可能な牌
  const [canKakanTiles, setCanKakanTiles] = useState<string[]>([]); // 加カン可能な牌
  const [canDaiminkan, setCanDaiminkan] = useState<boolean>(false); // ダイミンカン可能かどうか
  const [furiten, setFuriten] = useState<boolean>(false); // フリテンかどうか
  const [immediatelyAfterDraw, setImmediatelyAfterDraw] =
    useState<boolean>(false); // 山から牌を引いた直後かどうか
  const [yourDiscardList, setYourDiscardList] = useState<string[]>([]); // 自分の捨て牌
  const [opponentDiscardList, setOpponentDiscardList] = useState<string[]>([]); // 相手の捨て牌
  const [yourPoint, setYourPoint] = useState<number>(25000); // 自分の点数
  const [opponentPoint, setOpponentPoint] = useState<number>(25000); // 相手の点数
  const [remainingDeck, setRemainingDeck] = useState<number>(0); // 山牌の残り枚数
  const [isParent, setIsParent] = useState<boolean>(false); // 自分が親かどうか
  const [anyButtonVisible, setAnyButtonVisible] = useState(false); // どれかのボタンが表示されているかどうか

  const [gameStatus, setGameStatus] = useState<string | undefined>();
  const [turnPlayerId, setTurnPlayerID] = useState<number | undefined>();

  const prevTileListLength = useRef(tileList.length); // 前の手牌の枚数を追跡

  const gameId = gameData.id;
  const playerId = gameData.player.id;

  // ゲームステータスのセットと必要があれば画面遷移
  function setGameStatusWithChangePage(status: string | undefined) {
    // インターバルに画面遷移する
    setGameStatus(status);
    if (status === 'interval' || status === 'end_of_game') {
      goToInterval();
    }
  }

  // 対局情報の取得
  const fetchGameInfo = (
    gameId: number,
    playerId: number,
    setGameStatusWithChangePage: (status: string | undefined) => void,
    setTurnPlayerID: (id: number | undefined) => void,
    setIsParent: (isParent: boolean) => void,
    setYourPoint: (point: number) => void,
    setOpponentPoint: (point: number) => void,
    setRemainingDeck: (count: number) => void
  ) => {
    requestWithRetry({
      maxRetryCount: 3,
      method: HttpMethod.GET,
      path: `/games/${gameId}`,
      onError: function (error: unknown) {
        console.error(`対局情報の取得中にエラーが発生しました:${error}`);
      },
      onSuccess: function (response: AxiosResponse) {
        setGameStatusWithChangePage(response.data['status']);
        setTurnPlayerID(response.data['turn_player_id']);
        setIsParent(response.data['parent_player_id'] === playerId);
        setYourPoint(
          response.data['player_1_id'] === playerId
            ? response.data['player_1_point']
            : response.data['player_2_point']
        );
        setOpponentPoint(
          response.data['player_1_id'] === playerId
            ? response.data['player_2_point']
            : response.data['player_1_point']
        );
        setRemainingDeck(52 - response.data['deck_position']); // 山牌の残り枚数 牌の合計枚数は52枚
      },
    });
  };
  useEffect(() => {
    fetchGameInfo(
      gameId,
      playerId,
      setGameStatusWithChangePage,
      setTurnPlayerID,
      setIsParent,
      setYourPoint,
      setOpponentPoint,
      setRemainingDeck
    );

    const cable = ActionCable.createConsumer(webSocketUrl);

    const subscription = cable.subscriptions.create(
      { channel: 'GameStatusChannel', game_id: gameId },
      {
        received: async (data) => {
          setGameStatusWithChangePage(data.status);
          setTurnPlayerID(data.turn_player_id);
          setIsParent(data.parent_player_id === playerId ? true : false);
          setYourPoint(
            data.player_1_id === playerId
              ? data.player_1_point
              : data.player_2_point
          );
          setOpponentPoint(
            data.player_1_id === playerId
              ? data.player_2_point
              : data.player_1_point
          );
          setRemainingDeck(52 - data.deck_position); // 山牌の残り枚数 牌の合計枚数は52枚
        },
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [gameId]);

  // 手牌の取得
  const fetchHands = (
    gameId: number,
    playerId: number,
    setTileList: (data: string[]) => void,
    setMeld: (data: string[][]) => void,
    setOpponentHandNum: (num: number) => void,
    setOpponentMeld: (data: string[][]) => void,
    setCanTsumo: (can: boolean) => void,
    setCanRon: (can: boolean) => void,
    setCanPon: (can: boolean) => void,
    setCanCallkan: (can: boolean) => void,
    setCanAnkanTiles: (tiles: string[]) => void,
    setCanKakanTiles: (tiles: string[]) => void,
    setCanDaiminkan: (can: boolean) => void,
    setFuriten: (isFuriten: boolean) => void,
    setImmediatelyAfterDraw: (isImmediatelyAfterDraw: boolean) => void
  ) => {
    requestWithRetry({
      maxRetryCount: 3,
      method: HttpMethod.GET,
      path: `/hands/${gameId}/${playerId}`,
      onError: function (error: unknown) {
        console.error(`手牌取得中にエラーが発生しました:${error}`);
      },
      onSuccess: function (response: AxiosResponse) {
        setTileList(response.data.hand);
        setMeld(response.data.meld);
        setOpponentHandNum(response.data.opponent_hand_num);
        setOpponentMeld(response.data.opponent_meld);
        setCanTsumo(response.data.can_tsumo);
        setCanRon(response.data.can_ron);
        setCanPon(response.data.can_pon);
        setCanCallKan(response.data.can_call_kan);
        setCanAnkanTiles(response.data.can_ankan_tiles);
        setCanKakanTiles(response.data.can_kakan_tiles);
        setCanDaiminkan(response.data.can_daiminkan);
        setFuriten(response.data.furiten);
        setImmediatelyAfterDraw(response.data.immediately_after_draw);
      },
    });
  };
  useEffect(() => {
    fetchHands(
      gameId,
      playerId,
      setTileList,
      setMeld,
      setOpponentHandNum,
      setOpponentMeld,
      setCanTsumo,
      setCanRon,
      setCanPon,
      setCanCallKan,
      setCanAnkanTiles,
      setCanKakanTiles,
      setCanDaiminkan,
      setFuriten,
      setImmediatelyAfterDraw
    );
  }, [gameStatus, turnPlayerId, gameId, playerId]);

  // 捨て牌の取得
  const fetchDiscards = (
    gameId: number,
    playerId: number,
    setYourDiscardList: (data: string[]) => void,
    setOpponentDiscardList: (data: string[]) => void
  ) => {
    requestWithRetry({
      maxRetryCount: 3,
      method: HttpMethod.GET,
      path: `/discards/${gameId}/${playerId}`,
      onError: function (error: unknown) {
        console.error(`捨て牌取得中にエラーが発生しました:${error}`);
      },
      onSuccess: function (response: AxiosResponse) {
        setYourDiscardList(response.data.you);
        setOpponentDiscardList(response.data.other);
      },
    });
  };
  useEffect(() => {
    fetchDiscards(gameId, playerId, setYourDiscardList, setOpponentDiscardList);
  }, [gameStatus, turnPlayerId, gameId, playerId]);

  // アクション失敗時に対局情報、手牌、捨て牌を再取得
  const fetchAllInfo = () => {
    fetchGameInfo(
      gameId,
      playerId,
      setGameStatusWithChangePage,
      setTurnPlayerID,
      setIsParent,
      setYourPoint,
      setOpponentPoint,
      setRemainingDeck
    );
    fetchHands(
      gameId,
      playerId,
      setTileList,
      setMeld,
      setOpponentHandNum,
      setOpponentMeld,
      setCanTsumo,
      setCanRon,
      setCanPon,
      setCanCallKan,
      setCanAnkanTiles,
      setCanKakanTiles,
      setCanDaiminkan,
      setFuriten,
      setImmediatelyAfterDraw
    );
    fetchDiscards(gameId, playerId, setYourDiscardList, setOpponentDiscardList);
  };

  // フリーズバグがあるので、5秒ごとに情報を再取得する
  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchAllInfo();
    }, 5000); // 5秒ごとにfetchAllInfoを実行

    return () => clearInterval(intervalId);
  }, [gameId, playerId]);

  // 打牌処理
  const handleTileClick = async (tile: string) => {
    // 自分のターンでなおかつ打牌選択の場合のみクリック時に打牌処理を行う
    if (playerId === turnPlayerId && gameStatus === 'tile_selection') {
      requestWithRetry({
        maxRetryCount: 0,
        method: HttpMethod.DELETE,
        path: `/hands/${gameId}/${playerId}/${tile}`,
        onError: function (error: unknown) {
          console.error(`打牌APIリクエスト中にエラーが発生しました:${error}`);
        },
      });
    }
  };

  // ツモ処理
  const isTsumoButtonVisible =
    canTsumo && gameStatus === 'tile_selection' && playerId === turnPlayerId;
  const handleTsumoClick = () => {
    requestWithRetry({
      maxRetryCount: 0,
      method: HttpMethod.POST,
      path: `/tsumo/${gameId}/${playerId}`,
      onError: function (error: unknown) {
        console.error(`ツモ処理中にエラーが発生しました:${error}`);
      },
    });
  };

  // ロン処理
  const isRonButtonVisible =
    canRon && gameStatus === 'meld_selection' && playerId === turnPlayerId;
  const handleRonClick = () => {
    requestWithRetry({
      maxRetryCount: 0,
      method: HttpMethod.POST,
      path: `/ron/${gameId}/${playerId}`,
      onError: function (error: unknown) {
        console.error(`ロン処理中にエラーが発生しました:${error}`);
      },
    });
  };

  // ポン処理
  const isPonButtonVisible =
    canPon && gameStatus === 'meld_selection' && playerId === turnPlayerId;
  const handlePonClick = () => {
    requestWithRetry({
      maxRetryCount: 0,
      method: HttpMethod.POST,
      path: `/pon/${gameId}/${playerId}`,
      onError: function (error: unknown) {
        console.error(`ポン処理中にエラーが発生しました:${error}`);
      },
    });
  };

  // カン宣言処理
  const isCallKanButtonVisible =
    canCallKan && gameStatus === 'tile_selection' && playerId === turnPlayerId;
  const handleCallKanClick = () => {
    requestWithRetry({
      maxRetryCount: 0,
      method: HttpMethod.POST,
      path: `/kan/call/${gameId}/${playerId}`,
      onError: function (error: unknown) {
        console.error(`カン宣言時にエラーが発生しました:${error}`);
      },
    });
  };

  // 暗カン(牌選択)処理
  const handleAnkanClick = (tile: string) => {
    requestWithRetry({
      maxRetryCount: 0,
      method: HttpMethod.POST,
      path: `/ankan/${gameId}/${playerId}/${tile}`,
      onError: function (error: unknown) {
        console.error(`暗カン宣言時にエラーが発生しました:${error}`);
      },
    });
  };

  // 加カン(牌選択)処理
  const handleKakanClick = (tile: string) => {
    requestWithRetry({
      maxRetryCount: 0,
      method: HttpMethod.POST,
      path: `/kakan/${gameId}/${playerId}/${tile}`,
      onError: function (error: unknown) {
        console.error(`加カン宣言時にエラーが発生しました:${error}`);
      },
    });
  };

  // ダイミンカン処理
  const isDaiminkanButtonVisible =
    canDaiminkan &&
    gameStatus === 'meld_selection' &&
    playerId === turnPlayerId;
  const handleDaiminkanClick = () => {
    requestWithRetry({
      maxRetryCount: 0,
      method: HttpMethod.POST,
      path: `/daiminkan/${gameId}/${playerId}`,
      onError: function (error: unknown) {
        console.error(`ダイミンカン処理中にエラーが発生しました:${error}`);
      },
    });
  };

  // キャンセル処理
  const isCancelButtonVisible =
    (canPon || canDaiminkan || canRon) &&
    gameStatus === 'meld_selection' &&
    playerId === turnPlayerId;
  const handleCancelClick = () => {
    requestWithRetry({
      maxRetryCount: 0,
      method: HttpMethod.POST,
      path: `/cancel/${gameId}/${playerId}`,
      onError: function (error: unknown) {
        console.error(`キャンセル処理中にエラーが発生しました:'${error}`);
      },
    });
  };

  // 再描画処理
  const handleReloadClick = () => {
    fetchAllInfo();
  };

  // 手牌が増えたときに効果音を鳴らす
  useEffect(() => {
    if (tileList.length > prevTileListLength.current) {
      // 手牌が増えたときのみ効果音を鳴らす
      if (remainingDeck !== 25 || isParent) {
        // 初手の子の場合以外は音を鳴らす
        drawSound();
      }
    }
    prevTileListLength.current = tileList.length; // 現在の手牌の枚数を更新
  }, [tileList.length]);

  // ボタンが表示されたときに効果音を鳴らす
  useEffect(() => {
    const newVisibility =
      isTsumoButtonVisible ||
      isRonButtonVisible ||
      isPonButtonVisible ||
      isCancelButtonVisible;
    setAnyButtonVisible((prevVisibility) => {
      if (!prevVisibility && newVisibility) {
        showActionSound();
      }
      return newVisibility;
    });
  }, [
    isTsumoButtonVisible,
    isRonButtonVisible,
    isPonButtonVisible,
    isCancelButtonVisible,
  ]);

  return (
    <ThemeProvider theme={Theme}>
      <BattleFieldContainer>
        <div className="hand-area opponent-hand-area">
          <OpponentMeld meld={opponentMeld} />
          <div className="hand-wrap">
            <OpponentHand opponentHandNum={opponentHandNum} />
          </div>
        </div>
        <div className="discard-container">
          <Discard tiles={opponentDiscardList} is_your_discard={false} />
        </div>
        <div className="center-panel-container">
          <ReloadButton onClick={handleReloadClick} />
          <CenterPanel
            yourPoint={yourPoint}
            opponentPoint={opponentPoint}
            remainingDeck={remainingDeck}
            isParent={isParent}
            playerId={playerId}
            turnPlayerId={turnPlayerId}
          />
        </div>

        <div className="discard-container">
          <Discard tiles={yourDiscardList} is_your_discard={true} />
        </div>
        <div className="action-buttons">
          <FuritenLabel isVisible={furiten} />
          <ActionButton
            isVisible={isTsumoButtonVisible}
            onClick={handleTsumoClick}
            label="ツモ"
          />
          <ActionButton
            isVisible={isRonButtonVisible}
            onClick={handleRonClick}
            label="ロン"
          />
          <ActionButton
            isVisible={isPonButtonVisible}
            onClick={handlePonClick}
            label="ポン"
          />
          <ActionButton
            isVisible={isDaiminkanButtonVisible}
            onClick={handleDaiminkanClick}
            label="カン" // ダイミンカン
          />
          <ActionButton
            isVisible={isCallKanButtonVisible}
            onClick={handleCallKanClick}
            label="カン" // 暗カン、加カン
          />
          <ActionButton
            isVisible={isCancelButtonVisible}
            onClick={handleCancelClick}
            label="キャンセル"
          />
        </div>
        <div className="hand-area my-hand-area">
          <div className="hand-wrap">
            <Hand
              tiles={tileList}
              gameStatus={gameStatus}
              canAnkanTiles={canAnkanTiles}
              canKakanTiles={canKakanTiles}
              isTurnPlayer={playerId === turnPlayerId}
              immediatelyAfterDraw={immediatelyAfterDraw}
              onTileClick={handleTileClick}
              handleAnkanClick={handleAnkanClick}
              handleKakanClick={handleKakanClick}
            />
          </div>
          <Meld meld={meld} />
        </div>
      </BattleFieldContainer>
    </ThemeProvider>
  );
}

export default BattleField;
